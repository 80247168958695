import React from 'react';
import Helmet from 'react-helmet';
import { Flex, Box, Heading } from 'rebass';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { graphql } from 'gatsby'

import Layout from '../components/Layout';

export const query = graphql`
query ShippingPolicyQuery {
  store: site {
    siteMetadata {
      gatsbyStorefrontConfig {
        storeName
        storeDescription
      }
    }
  }
}
`

function Home({ data, location }) {
  const {
    storeName,
    storeDescription,
  } = data.store.siteMetadata.gatsbyStorefrontConfig
  const pageName = 'Shipping Policy'
  const title = `${pageName} — ${storeName}`

  return (
    <Layout>
      <GatsbySeo
        title={title}
        description={storeDescription}
        openGraph={{
          url: location.href,
          title: title,
          description: storeDescription,
          images: [{
            url: location.origin + '/images/logo_800_276.png',
            width: 800,
            height: 276,
          },
          {
            url: location.origin + '/images/logo_800_800.png',
            width: 800,
            height: 800,
          },
          {
            url: location.origin + '/images/logo_382_113.png',
            width: 382,
            height: 113,
          }],
        }}
      />
      <Flex flexWrap="wrap" px={2} pt={3} mx="auto" style={{ maxWidth: 1300 }}>
        <Helmet title={title} defer={false} />
        <Heading as="h1" fontSize={[30, 36, 42]} my={3}>
          {pageName}
        </Heading>
        <Box>
          <p>Shipping/delivery time will vary depending on your location and delivery method.</p>
          <p><strong>Domestic(Norway)</strong><br />Free shipping for orders over 500 NOK. For orders under 500 NOK shipping cost is 100NOK. Shipping time is usually 2-4 days, you will be able to track your shipment.</p>
          <p><strong>International</strong><br />Shipping time is usually 7-14 days, dependingon your location. You will be able to track your shipment. Shipping cost 200 NOK.<br /></p>
          <p><strong>NOTE:</strong> We do not offer international shipping for TDE Merchandise (TDE Merch) products only. In the case you buy a TDE Merchandise product together with any of our main products – we are able to ship it in the same package.</p>
          <p>We are not responsible for any shipping delays if that should occur when the package is in the hands of the shipping company, but in the case that should occur we will help you as much as we can in order for you to receive the package as soon as possible.<br /> You are responsible for making sure that the delivery name and address are correct and we recommend you to double-check this to be on the safe side. In the case you discover that you have given us any wrong information, let us know as soon as possible.</p>
          <p><br /><strong>CUSTOMS AND DUTIES</strong><br />Delivery duties are not included in the item price when shipping to most countries. This means If you are ordering from within your own country zone, you may be liable to pay import duties, VAT or local Sales Taxes where applicable on receipt of your order.<br /> In most cases, any customs or import duties are charged once the parcel reaches its destination country. Any charges on a parcel must be paid by the person receiving the parcel.<br /> We have no control over these charges and we can’t tell you what the cost would be, as customs policies and import duties vary widely from country to country. <br /><br /> For returns, see refund policy.<br /><br /></p>
        </Box>
      </Flex>
    </Layout>
  );
}

export default Home;
